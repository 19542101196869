exports.components = {
  "component---src-pages-2024-jsx": () => import("./../../../src/pages/2024.jsx" /* webpackChunkName: "component---src-pages-2024-jsx" */),
  "component---src-pages-2025-jsx": () => import("./../../../src/pages/2025.jsx" /* webpackChunkName: "component---src-pages-2025-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-manage-preferences-jsx": () => import("./../../../src/pages/manage-preferences.jsx" /* webpackChunkName: "component---src-pages-manage-preferences-jsx" */),
  "component---src-pages-options-jsx": () => import("./../../../src/pages/options.jsx" /* webpackChunkName: "component---src-pages-options-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-sponsorship-launch-jsx": () => import("./../../../src/pages/sponsorship/launch.jsx" /* webpackChunkName: "component---src-pages-sponsorship-launch-jsx" */),
  "component---src-pages-sponsorship-welcome-jsx": () => import("./../../../src/pages/sponsorship/welcome.jsx" /* webpackChunkName: "component---src-pages-sponsorship-welcome-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */)
}

